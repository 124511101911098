import Alpine from 'alpinejs';
import htmx from 'htmx.org';
import sponsors from "./sponsors";
import Swiper from "swiper/bundle";

import collapse from '@alpinejs/collapse'
import masonry from 'alpinejs-masonry'
import focus from '@alpinejs/focus'
import intersect from '@alpinejs/intersect'

Alpine.plugin(collapse)
Alpine.plugin(masonry)
Alpine.plugin(focus)
Alpine.plugin(intersect)

window.Swiper = Swiper;
window.Alpine = Alpine;
window.htmx = htmx;

Alpine.data('Sponsors', sponsors);


if(htmxNonce !== undefined) {
    document.addEventListener('htmx:configRequest', function(event) {
        event.detail.headers['X-WP-Nonce'] = htmxNonce;
    });
}

document.addEventListener('DOMContentLoaded', function() {
    Alpine.start();
});